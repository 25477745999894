import React from 'react'
import './NavBar.css'
import logo2 from '../../assets/Prime_Video_Logo.png'
import logoBemovil from '../../assets/logo_be_blanco.png'

function NavBar() {
    return (
        <nav className="nav-bar">
            <ul className="ul-principal">
                <img 
                alt='' 
                className="iconsNavBar"
                style={{backgroundColor: "transparent"}}
                src={logoBemovil}></img>
            </ul>

            <ul className="ul-principal2">
                <img  
                alt='' 
                className="iconsNavBar2"
                style={{backgroundColor: "transparent",padding: '0 1rem', textAlign:"center"}}
                src={logo2}></img>
            </ul>
            {/* <img src={logo2}></img> */}

            {/* <ul className="ul-principal">
                <li>
                    <img  style={{width: "80%",height: "10%", marginTop: -20}} src={logoBemovil}></img>
                </li>

            </ul> */}
        </nav>
    )
}

export default NavBar
