import React from 'react'
import './Information.css'
function Information() {
    return (
        <div className="containerInformation">
            <div style={{alignItems: "center", textAlign: "center", justifyContent: "center"}}
                className="containerSecondInformation">
                <p style={{ textAlign: "left", color: "white" }}>
                    Disfruta de miles de peliculas y series originales y exclusivas de Amazon,
                    además de peliculas y series populares por
                    {" "}<b style={{ color: "#17e9f9" }}>$22.900 COP/mes</b>, comprando a través de tu vendedor
                    autorizado.
                </p>
                {/* <div style={{ textAlign: "left", marginTop: 13 }}>
                    <a href="#" style={{
                        background: "red",
                        width: "35%",
                        padding: 7,
                        textDecoration: "none",
                        color: "white",
                        borderRadius: 3
                    }} className="btnAyuda">
                        ¿Necesitas ayuda?
                    </a>

                </div> */}
            </div>
            
        </div>
    )
}
// color: "#03e9f4" 
export default Information
