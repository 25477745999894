import './App.css';
import NavBar from './components/navBar/NavBar';
import FormPines from './components/form/FormPines';
import SliderFilms from './components/slider/SliderFilms';
import Information from './components/information/Information';
import Footer from './components/footer/Footer';
import Routes from './components/routes/router';

function App() {
  return (
      <Routes/>
    // <body>
    //   <NavBar />
    //   <div className="store-wrapper">
    //     <Information />
    //     <FormPines />
    //   </div>
    //   {/* <SliderFilms /> */}
    //   <div>
    //     <Footer/>
    //   </div>
    // </body>
  );
}

export default App;
