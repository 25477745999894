import React, { useState } from 'react'
import './Footer.css'
import logo2 from '../../assets/logo2.png'
import logoBemovil from '../../assets/lobo_be_blanco_ho.png'

function Footer() {
    const [display, setDisplay] = useState(false)

    return (
        <footer>
            <nav className="footer-bar">
                <h2 className="textDistribuidor">Distribuidor Autorizado</h2>
                
                <ul className="ul-principal">
                    <img  
                    className="iconsFooterBar"
                    style={{backgroundColor: "transparent",padding: 0}}
                    src={logoBemovil}></img>
                </ul>


            </nav>
        </footer>
        
    )
}

export default Footer
