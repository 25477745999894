import React from 'react'
import * as Colors from './../utils/Colors'

function ModalMessage({header, message, color, modal, setModal, url, cleanForm}) {
    const setForm = ()=>{
        cleanForm();
        if(url !== '/'){
            if(!url.includes("https://")){
                url = "https://"+url
            }
            window.open( url, '_blank') 
        }
    }
    return (
        <div id="myModal" className="modal"
            style={modal ? { display: "flex" } : {}}>

            <div className="modal-content">
                <div className="modal-header"
                    style={{ backgroundColor: color }}>
                    {/* <span className="close"
                        onClick={() => setModal(false)}>&times;</span> */}
                    <h3>{header}</h3>

                </div>

                <div className="modal-body">
                    <p>
                        {message.split('\n').map((linea, index) => (
                            <React.Fragment key={index}>
                                {linea}
                                <br />
                            </React.Fragment>
                        ))}
                    </p>
                </div>
                <div className="modal-footer">
                    <button className="btnConfirmModal"
                        onClick={() => {
                            setModal(false)
                            setForm()
                        }}
                        style={{ backgroundColor: Colors.ORANGE_BE }}>
                        Continuar
                    </button>
                    <button className="btnConfirmModal"
                        onClick={() => {setModal(false)
                            // cleanForm()
                        }}
                        style={{ backgroundColor: Colors.GRAY_SECOND_BE }}>
                        Cancelar
                    </button>
                </div>
            </div>

        </div>
    )
}

export default ModalMessage
