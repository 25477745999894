import React from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import general from '../general/general'

function Routes() {
    return (
        <BrowserRouter basename='/canjea/'>
            <Switch>
                <Route exact path='/' component={general}/>
            </Switch>
        </BrowserRouter>
    )
}

export default Routes