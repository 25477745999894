import '../../App.css';
import NavBar from '../navBar/NavBar';
import FormPines from '../form/FormPines';
import SliderFilms from '../slider/SliderFilms';
import Information from '../information/Information';
import Footer from '../footer/Footer';
function General() {
    return (
        <>
            <NavBar />
            <div className="store-wrapper">
                <Information />
                <FormPines />
            </div>
            {/* <SliderFilms /> */}
            <Footer/>
        </>
    );
}

export default General;
