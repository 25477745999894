import React, { useState } from 'react'
import './FormPines.css'
// import axios from 'axios';
import FullPageLoader from '../utils/Loading';
import ModalMessage from '../utils/ModalMessage';
import ModalMessageAction from '../utils/ModalMessageAction';
import * as Colors from './../utils/Colors'

function FormPines() {
    // https://www.primevideo.com/

    const [pin, setPin] = useState('')
    const [email, setEmail] = useState('');
    const [confirmarEmail, setConfirmarEmail] = useState('')
    const [validEmail, setValidEmail] = useState(false)
    const [confirmValidEmail, setConfirmValidEmail] = useState(false)
    const [validatePin, setValidatePin] = useState(false)
    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState(false)
    const [modal2, setModal2] = useState(false)
    const [message, setMessage] = useState('')
    const [headerMessage, setheaderMessage] = useState('')
    const [color, setColor] = useState(Colors.ORANGE_BE)
    const [urlR, setUrlR] = useState('')

    const cleanForm = () => {
        setPin('')
        setEmail('');
        setConfirmarEmail('')
    }
    const validateForm = () => {
        let emailValidate = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if (emailValidate.test(email)) {
            setValidEmail(false)
        } else {
            setValidEmail(true)
        }
        if (pin === '') {
            setValidatePin(true)
        }
    }
    const validateConfirmEmail = () => {
        if (email === confirmarEmail) {
            setConfirmValidEmail(false)
            return false
        } else {
            setConfirmValidEmail(true)
            return true
        }
    }
    const query_membership = async () => {
        validateForm()
        if (!validateConfirmEmail() && pin !== '' && email !== '') {
            let data = {
                pin: pin,
                email: email,
            }
            setLoading(true)
            try {
                const response = await fetch('https://amz-pinex.bemovil.net/pin/verificarMembresia', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
                const jsonData = await response.json();
                if (response.ok) {
                    setColor(Colors.PURPLE_BE);
                    setUrlR("/");
                    setMessage(jsonData.data.description);
                    setheaderMessage("Información Importante")
                    setModal2(true)
                    
                }else{
                    setColor(Colors.ORANGE_BE);
                    setUrlR("/");
                    setMessage(jsonData.message);
                    setheaderMessage("Alerta")
                    setModal(true)
                }

                
                setLoading(false)

            } catch (error) {
                setColor(Colors.ORANGE_BE);
                setUrlR("/");
                setMessage('Error en consulta.');
                setheaderMessage("Alerta")
                
                setModal(true)
                setLoading(false)
            }
        }
    };

    const topUpMembership = async () => {
        validateForm()
        if (!validateConfirmEmail() && pin !== '' && email !== '') {
            let data = {
                pin: pin,
                email: email,
            }
            setLoading(true)
            try {
                const response = await fetch('https://amz-pinex.bemovil.net/pin/validarPin', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });
                const jsonData = await response.json();
                if (response.ok) {
                    setColor(Colors.PURPLE_BE);
                    setUrlR(jsonData.data.info.url);
                    setMessage(jsonData.message + ",\n link: " + jsonData.data.info.url);
                    setheaderMessage("Proceso exitoso")
                    setModal(true)
                    
                }else{
                    setColor(Colors.ORANGE_BE);
                    setUrlR("/");
                    setMessage(jsonData.message);
                    setheaderMessage("Información")
                    setModal(true)
                }

                
                setLoading(false)

            } catch (error) {
                setColor(Colors.ORANGE_BE);
                setUrlR("/");
                setMessage('Error en consulta.');
                setheaderMessage("Información")
                
                setModal(true)
                setLoading(false)
            }
        }
    };
    // const peticion = async () => {
    //     validateForm()
    //     setModal(false)
    //     if (!validateConfirmEmail() && pin !== '' && email !== '') {
    //         let form = {
    //             pin: pin,
    //             email: email
    //         }
    //         console.log(form)
    //         console.log("enviando peticion")
    //         setLoading(true)
    //         await axios.post(
    //             'https://amz-pinex.bemovil.net/pin/validarPin', form
    //         ).then(res => {
    //             console.log("respuesta del servidor: ", res)
    //             let respuesta = res.data
    //             let codigo = respuesta.codigo
    //             console.log(":veaass", respuesta)
    //             switch (codigo) {
    //                 case 'P-1101':
    //                     setColor("#280a56")
    //                     setUrlR(res.data.url);
    //                     setMessage('El pin es valido, dale clic en el boton para continua tu redencion del pin, iniciando sesion en Amazon Prime Video o creando una cuenta');
    //                     setheaderMessage("Enhorabuena")
    //                     break;

    //                 case 'S-1103':
    //                     setColor("#280a56");
    //                     setUrlR("/");
    //                     setMessage('Error S-1103, comuniquese con el vendedor');
    //                     setheaderMessage("Alerta")
    //                     break;

    //                 default:
    //                     setColor("#280a56");
    //                     setUrlR("/");
    //                     setMessage("error fatal: ", res.data.message," , comuniquese con su vendedor");
    //                     setheaderMessage("Ha ocurrido un error")
    //                     break;
    //             }

    //             setModal(true)
    //             setLoading(false)
                
    //         }).catch( error => {
    //             let respuesta = JSON.parse(error.request.response)
    //             console.log("request", respuesta);
    //             let codigo = respuesta['codigo'];
                    
    //             switch (codigo) {
    //                 case 'P-1103':
    //                     setMessage('PIN no valido, PIN YA REDIMIDO');
    //                     break;

    //                 case 'S-1103':
    //                     setMessage('Error S-1103 comuniquese con su vendedor');
    //                     break;

    //                 case 'P-1104':
    //                     setMessage('Pin no valido, comuniquese con su vendedor');
    //                     break;
                    
    //                 case 'P-1100':
    //                     setMessage('Error fatal P-1100, comuniquese con su vendedor');
    //                     break;

    //                 case 'P-1144':
    //                     setMessage('Error fatal P-1144, verifique que todos los datos sean correctos');
    //                     break;

    //                 default:
    //                     setMessage(`Error ${codigo}`)
    //                     break;
    //             }

    //             setColor("#ff6600")
    //             setUrlR("/")
    //             setheaderMessage("Alerta")
    //             setModal(true)
    //             setLoading(false)
                
    //             }
    //         )
    //     } else {
    //         console.log("faltan los campos que esten bien")
    //     }

    // }

    return (
        <div className="containerFormPines">
            <div className="login-box" >
                <h2>Canjea tu código {" "}<b style={{ color: "#17e9f9" }}>¡aqui!</b></h2>
                    <div className="user-box">
                        <input type="text" name="" required="" value={pin} onChange={(e) => {
                            setPin(e.target.value)
                            setValidatePin(false)
                        }} />
                        <label>Pin</label>
                        <p className="col-20"
                            style={validatePin ? { display: "block" } : { display: "none" }}>
                            Debes ingresar un pin
                        </p>
                    </div>

                    <div className="user-box">
                        <input type="email" name="" required="" value={email} onChange={(e) => {
                            setEmail(e.target.value)
                            setValidEmail(false)
                        }} />
                        <label>Correo electronico</label>
                        <p className="col-20"
                            style={validEmail ? { display: "block" } : { display: "none" }}>
                            Debes ingresar un correo valido
                        </p>

                    </div>

                    <div className="user-box">
                        <input type="email" name="" required="" value={confirmarEmail} onChange={(e) => {
                            setConfirmarEmail(e.target.value)
                            setConfirmValidEmail(false)

                        }} />
                        <label>Confirmacion correo electronico</label>
                        <p className="col-20"
                            style={confirmValidEmail ? { display: "block" } : { display: "none" }}>
                            Los correos no coinciden. Porfavor verifica
                        </p>

                    </div>
                    <div className='containerTermConditions'>
                        <p className='textTermConditions'>Al hacer clic en "comenzar" estas de acuerdo con los terminos y condiciones de <a href='https://www.bemovil.net/terminos-y-condiciones-amazon'> <font color="#11acee"> Bemovil </font></a> y  <a href='https://www.primevideo.com/help?nodeId=202064890&ref_=pe_2807340_215579920' > <font color="#11acee"> Amazon Prime Video. </font></a> Amazon, Amazon Prime Video y todos los logos relacionados, son marcas registradas de Amazon.com Inc o sus afiliados. Oferta sujeta a cambio.</p>
                    </div>
                    

                    <button href="#" onClick={query_membership}>Comenzar</button>
                {loading &&
                    <FullPageLoader />
                }
                <ModalMessage
                    color={color}
                    header={headerMessage}
                    message={message}
                    modal={modal}
                    setModal={setModal}
                    url = {urlR}
                    cleanForm={cleanForm}
                />
                <ModalMessageAction
                    color={color}
                    header={headerMessage}
                    message={message}
                    modal={modal2}
                    setModal={setModal2}
                    url = {urlR}
                    action = {topUpMembership}
                    cleanForm={cleanForm}
                />

            </div>
        </div>

    )
}

export default FormPines

